import React, { Fragment,  useState,useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import setAuthToken from './utils/setAuthToken';

import Home from './components/pages/Home';
import About from './components/pages/About';
import TwoPercent from './components/pages/TwoPercent';
import Contact from './components/pages/Contact';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Alerts from './components/layout/Alerts';
import PrivateRoute from './components/routing/PrivateRoute';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {

  const [currentSrc, updateSrc] = useState('./img/background3.jpg');

  let src = './img/background3.png'
  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
    updateSrc(src);
    }
  }, [src])

  return (
    <div style={{ backgroundColor: 'pink', backgroundImage: `url(${currentSrc})`, backgroundRepeat: 'no-repeat' ,backgroundSize: 'cover' }} >
    <AuthState>
        <AlertState>
          <Router>
            <Fragment>
              <Navbar />
              <div className='container content' >
              
                <Alerts />
                <Switch>
                  <PrivateRoute exact path='/' component={Home} />
                  <Route exact path='/about' component={About} />
                  <Route exact path='/twopercent' component={TwoPercent} />
                  <Route exact path='/contact' component={Contact} />
                  

                  <Route exact path='/register' component={Register} />
                  <Route exact path='/login' component={Login} />
                </Switch>
              </div>
              <Footer />
            </Fragment>
          </Router>
        </AlertState>
    </AuthState>
    </div>
  );
};

export default App;
