import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';

const Navbar = ({ title }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout, user } = authContext;

  const onLogout = () => {
    logout();
  };

  const authLinks = (
    <Fragment>
      <li>
        <a onClick={onLogout} href='#!'>
          <i className='fas fa-sign-out-alt'>
            {' '}
            <span className='hide-sm'>Logout</span>
          </i>
        </a>
      </li>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      {/* <li>
        <Link to='/register'>Register</Link>
      </li>
      <li>
        <Link to='/login'>Login</Link>
      </li> */}
      <li>
        <Link to='/about'>O nás</Link>
      </li>
      <li>
        <Link to='/twopercent'>2%</Link>
      </li>
      <li>
        <Link to='/contact'>Kontakt</Link>
      </li>


    </Fragment>
  );

  return (
<Fragment>

    <div className='navbar_logo_bg bg-tkrum-primary'>
          <img src="./img/logo/rumm3.png" className='navbar_logo' alt="" />
          </div>
    <div className='navbar bg-tkrum-primary' >
    <h1>
      {title}
    </h1>

    
  <ul>{guestLinks}{' | '}{isAuthenticated ? authLinks : <li><Link to='/'>Login</Link></li> }</ul>
    </div>

</Fragment>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: 'TK Rum'
};

export default Navbar;
