import React from 'react';

const Contact = () => {
  return (
    <div id='dimScreen' className='container' >
      <h1>Kontakt</h1>

      <p className='my-2'>
        <strong>
        Turistický klub RUM – klub balkánskej turistiky<br />
        </strong>
        Sputniková 5<br />
        821 02 Bratislava – Ružinov<br />
        Slovenská republika<br />
      </p>

      <p className='my-2'>
        <strong>Identifikačné číslo organizácie (IČO):</strong> 42270103
      </p>

      <p className='my-2'>
        <strong>Bankový účet klubu:</strong> Všeobecná úverová banka SK0602000000003149560157
      </p>

      <p className='my-2'>
        <strong>Registrácia na Ministerstve vnútra SR:</strong> wsli-900/90-40855
      </p>

      <p className='my-2'>
        <strong>E-mail:</strong> tkrum@tkrum.sk
      </p>
      
      <p className='my-2'>
        <strong>Správna rada</strong>
        <br />
        Predseda správnej rady: Ján Pukanec       <br />
        Člen správnej rady: Matúš Borik       <br />
        Člen správnej rady: Pavel Keštefrán       <br />
      </p>

      <p className='my-2'>
        <strong>Kontrolór</strong>       <br />
        Kontrolór: Juraj Tóth       <br />
      </p>
      
      <p className='my-2'>
        <strong> Hospodár</strong>         <br />
        Hospodár: Pavel Keštefrán       <br />
      </p>


    </div>
  );
};

export default Contact;






 
