import React from 'react';

const About = () => {
  return (
    <div id='dimScreen' className='container' >
      <h1>O nás</h1>
      <p className='my-2'>Sme malá skupina nadšencov, ktorým nevadí občas ošúpať podrážky a vybrať sa niekde do neznáma, či už do kopcov alebo len na nejaké zaujímavé miesto. Všetko to začalo po januári 2012, v tom čase to boli len náhodné akcie určené na odpojenie sa z bežnej betónovej rutiny v Bratislave. Sem – tam krátky výlet na Kamzík, nejaká ta vyhliadka ale všetko v norme. Žiadne zaberáky a naťahovanie sa, kto má hrubšie gumáky alebo ostrejšie mačky.
      </p>
      <p className='my-2'>
        <img src="./img/About/rum_members6-150x150.jpg" alt="" className='about_img about_img_l'/>
        Takto sa dala dokopy základná skupina ľudí, keď nás šlo 5, bol dôvod na oslavu. Jasné, už z názvu klubu vidíme, čo sme si brali na posilnenie. To sa však rapídne zúžilo v lete, keď sa nabehlo na náročnejší režim. Tatry, či už Vysoké alebo Nízke, zľahka „pričuchnúť“ aj k Alpám, prvé dvojtisícovky, improvizovaná navigácia a komplikovanejší terén nás už posúval k právu byť oslovovaní „turisti“.
      </p>
      <p className='my-2'>
        <img src="./img/About/rum_members4-150x150.jpg" alt="" className='about_img about_img_r'/>
        Akcii nebolo zrovna málo, na niektoré sme priberali prvých „externistov“ a tak padol nápad to celé zoficiálniť na papier. Po dlhej príprave materiálov sme konečne mali pokope všetky potrebné dokumenty a jediné, čo chýbalo bola ustanovujúca schôdza. Tú sa podarilo splniť na výbornú, predseda bol jednohlasne zvolený a po rýchlej papierovačke sa podarilo definovať základnú organizáciu a vytvoriť zápisnicu.
      </p>
      <p className='my-2'>
        <img src="./img/About/rum_members3-150x150.jpg" alt="" className='about_img about_img_l'/>
        Na to, koľko nás je, máme slušný počet akcii – za rok 2012 prekročili číslo 40. Niektoré ľahké, iné dali zabrať. Niektoré spotené od tepla, iné čvachtavé od topiaceho sa snehu. Nikde však nechýba zdravá dávka akcie a v prvom rade fantastická zábava. To je to, čo robí zážitok z túry niečim, na čo sa dá dni vopred tešiť.
      </p>
      <br />
      <p className='my-2'>
        <img src="./img/About/rum_members2-150x150.jpg" alt="" className='about_img about_img_r'/>
        A prečo „Klub Balkánskej Turistiky“? Veľa ľudí sa na to pýta, odpoveď však vôbec nie je zložitá. Bolo to za čias strednej školy, keď náš matikár a triedny v jednej osobe pravidelne pripomínal: „vy na tú hodinu chodíte ako takí balkánski turisti. V jednej ruke desiata, v druhej karty!“. Keďže to je velmi fajn človek, na počesť nekonečnej zábavy z jeho hodín sa patrilo definíciu zachovať. A tak, ako sme aj s tými kartami zapísali previerky na výbornú, také sú aj naše túry. Veď ono sa len zdá, že chýba organizácia :). 
      </p>
    </div>
  );
};

export default About;
