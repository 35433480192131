import React from 'react';

const TwoPercent = () => {
  return (
    <div id='dimScreen' className='container' >
      <h1>2% z dane z príjmu</h1>
      <p className='my-2'>Príjmy z poukázania 2% z dane z príjmu fyzických a právnických osôb sa môžu stať významným zdrojom financovania činnosti nášho turistického klubu. Z týchto peňazí môžeme hradiť niektoré turistické aktivity, nakúpiť spoločnú klubovú výbavu, nakúpiť knihy, časopisy či mapy do klubovej knižnice a iné. V roku 2020, teda za zdaňovacie obdobie 2019, sa bude náš klub opakovane uchádzať  o takýto zdroj financovania.</p>
      <p className='my-2'>Aj Vy môžete prispieť nášmu klubu poukázaním 2% z dane a vôbec to neovplyvní Váš príjem, pretože tieto prostriedky sú odvádzané z dane, ktorú musíte aj tak odviesť štátu. Aby ste to mali o niečo jednoduchšie, pripravili sme pre Vás podrobný postup krokov na poukázanie 2% z dane pre zamestnancov:</p>
      <ul style={{listStyleType: 'decimal', paddingLeft: '4rem'}}>
        <li className='my-1'>Do 17.02.2020 požiadajte zamestnávateľa o vykonanie ročného zúčtovania zaplatených preddavkov na daň. </li>
        <li className='my-1'>Taktiež požiadajte zamestnávateľa, aby Vám vystavil tlačivo Potvrdenie o zaplatení dane.</li>
        
        <li className='my-1'>Z tohto Potvrdenia si viete zistiť dátum zaplatenia dane a vypočítať:
a) 2% z Vašej zaplatenej dane (to je maximálna suma, ktorú môžete v prospech prijímateľa poukázať, ak ste v roku 2019 neboli dobrovoľníkom, alebo dobrovoľnícky odpracovali menej ako 40 hodín. Táto suma však musí byť minimálne 3 €. Zaokrúhľujeme smerom hore).
b) 3% z Vašej zaplatenej dane, ak ste v roku 2019 odpracovali dobrovoľnícky minimálne 40 hodín a získate o tom Potvrdenie od organizácie/organizácií, pre ktoré ste v roku 2019 dobrovoľnícky pracovali.</li>

        <li className='my-1'>Stiahnite si Vyhlásenie o poukázaní sumy do výšky 2% (3%) zaplatenej dane (Vyhlasenie 2020 v PDF).</li>

        <li className='my-1'>Vaše údaje a dátum zaplatenia dane zapíšte do Vyhlásenia spolu so sumou 2% (3%) z dane. Vyhlásenie nezabudnite označiť mestom, dopísať dátum vyplnenia a podpísať.</li>

        <li className='my-1'>Obe tieto tlačivá, čiže Vyhlásenie o poukázaní sumy do výšky 2% (3%) zaplatenej dane spolu s Potvrdením o zaplatení dane, doručte najneskôr do 30.04.2020 na daňový úrad podľa Vášho bydliska. V prípade nejasností čo najskôr kontaktujte Správu radu klubu (tkrumkbt@gmail.com). Ak ste poukázali 3% z dane, povinnou prílohou k Vyhláseniu a Potvrdeniu o zaplatení dane je aj Potvrdenie o odpracovaní minimálne 40 hodín dobrovoľníckej činnosti!!!</li>
      </ul>


      <p className='my-2'>Daňové úrady majú 90 dní od splnenia podmienok na to, aby previedli Vaše 2% (3%) v prospech prijímateľa.</p>
      <p className='my-2'>V prípade, že potrebujete získat podrobný postup krokov na poukázanie 1,0% (2%) z dane pre právnicke osoby alebo postup krokov na poukázania 2% (3%) z dane pre fyzické osoby, ktoré si sami podávajú daňové priznanie, môžete tieto informácie získať na nasledujúcej adrese: http://rozhodni.sk/poukazatel/ako-mam-poukazat-2-z-dane/</p>
    </div>
  );
};

export default TwoPercent;
