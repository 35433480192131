import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <p>2012-2020 Turistický klub RUM - klub balkánskej turistiky</p>
    </footer>
  )
}

export default Footer